export function getWeekDays() {
    let data = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return data;
}



export function generateTimes(startTime, endTime) {

    let timesLookup = [], start = 0, end = 1410;
    let value = 0, label = '';
  
    start = (startTime) ? Number(startTime) : start;
    end = (endTime) ? Number(endTime) : end;
    // For next day 
    if (startTime >= endTime) {
      end = 1410
    };
  
    for (let i = start; i <= end; i = i + 30) {
      let hours = Math.floor(Number(i) / 60);
      let minutesTime = Number(i) % 60;
      let minutes = (minutesTime && minutesTime == 30) ? 0.5 : 0;
      value = Number(hours + minutes);
  
      if (value == 0) {
        label = '12.00' + 'AM';
      } else if (value == 0.5) {
        label = '12.30' + 'AM';
      } else if (value >= 12 && value != 24) {
        label = (hours > 12 && hours < 22) ? '' : '';
        label = label + ((hours > 12) ? hours - 12 : hours);
        label = label + '.' + ((minutesTime == 0) ? '00' : minutesTime) + 'PM';
      } else {
        label = (hours < 10) ? '' : '';
        label = label + hours + '.' + ((minutesTime == 0) ? '00' : minutesTime) + 'AM';
      }
  
      timesLookup.push({
        value,
        hours,
        minutes,
        minutesTime,
        label,
        isNextDay: false
      });
    }
  
    // For next day 
    if (startTime >= endTime) {
      start = 0;
      end = endTime;
  
      for (let i = start; i <= end; i = i + 30) {
        let hours = Math.floor(Number(i) / 60);
        let minutesTime = Number(i) % 60;
        let minutes = (minutesTime && minutesTime == 30) ? 0.5 : 0;
        value = Number(hours + minutes);
  
        if (value >= 12 && value != 24) {
          label = (hours > 12 && hours < 22) ? '0' : '';
          label = label + ((hours > 12) ? hours - 12 : hours);
          label = label + '.' + ((minutesTime == 0) ? '00' : minutesTime) + 'PM';
        } else {
          label = (hours < 10) ? '' : '';
          label = (hours === 0) ? '12' : label + hours;
          label = label + '.' + ((minutesTime == 0) ? '00' : minutesTime) + 'AM';
        }
  
        label = label + '*';
  
        timesLookup.push({
          value,
          hours,
          minutes,
          minutesTime,
          label,
          isNextDay: true
        });
      }
    };
  
    return timesLookup;
  }
  