import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './SideMenu.css';
import bt from '../../components/commonStyle.css';
import { Nav, Navbar, Collapse, Button } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import messages from '../../locale/messages';
import cx from 'classnames';
import Link from '../Link';
import { adminLogout } from '../../actions/siteadmin/logout';
import { openHeaderModal } from '../../actions/siteadmin/modalActions';
import { connect } from 'react-redux';
import { flowRight as compose } from 'lodash';
import * as FontAwesome from 'react-icons/lib/fa';
import { choseToTheme } from '../../actions/currency/getCurrencyRates';
import history from '../../history'
import { validatePrivilege } from '../../helpers/adminPrivileges';
import { formatLocale } from '../../helpers/formatLocale';

//Images
import DashboardIcon from '../../../public/Icons/dashboard.svg';
import SiteSettingIcon from '../../../public/Icons/site_settings.svg';
import RidersIcon from '../../../public/Icons/manage_riders.svg';
import DriversIcon from '../../../public/Icons/manage_drivers.svg';
import CategoriesIcon from '../../../public/Icons/manage_categories.svg';
import VehiclesIcon from '../../../public/Icons/manage_vehicles.svg';
import BookingsIcon from '../../../public/Icons/manage_bookings.svg';
import PromoCodeIcon from '../../../public/Icons/manage_promo_code.svg';
import CompletedBooking from '../../../public/Icons/completed_bookings.svg';
import ChangePasswordIcon from '../../../public/Icons/changePassword.svg';
import CancalBookingIcon from '../../../public/Icons/cancelled_bookings.svg';
import ManageCurrencyIcon from '../../../public/Icons/manage_currency.svg';
import ManageCancelIcon from '../../../public/Icons/cancelReason.svg';
import RatingIcon from '../../../public/Icons/ratings.svg';
import NotificationIcon from '../../../public/Icons/manage_notifications.svg';
import LogOutIcon from '../../../public/Icons/logout.svg';
import MainSiteIcon from '../../../public/Icons/mainSite.svg';
import HomeSettingIcon from '../../../public/Icons/home_page_settings.svg';
import PayOutManageIcon from '../../../public/Icons/manage_payout.svg';
import PayOutManageFaildIcon from '../../../public/Icons/failed_payout.svg';
import CancelFaildIcon from '../../../public/Icons/cancel_reason.svg';
import staticPageIcon from '../../../public/Icons/manage_static_content.svg';
import ManageLocationIcon from '../../../public/Icons/manage_location.svg';
import RightArrowIcon from '../../../public/Icons/right-arrow.png';
import manageFareIcon from '../../../public/Icons/manage_fare.svg';
import LanguageIcon from '../../../public/Icons/languageIcon.svg';
import scheduleIcon from '../../../public/Icons/schedule_bookings.svg';
import precautionIcon from '../../../public/Icons/precaution_notification.svg';
import trackingIcon from '../../../public/Icons/tracking.svg';
import mobileAppIcon from '../../../public/Icons/mobile_app_settings.svg';
import contentPageIcon from '../../../public/Icons/content_management.svg';
import manualBookingsIcon from '../../../public/Icons/manual_bookings.svg';
import welcomeIcon from '../../../public/Icons/welcomeIcon.png';
import manageAdminIcon from '../../../public/Icons/manage_admins.svg';
import LogOutIconMobile from '../../../public/Icons/logoutMobile.svg';
import configSettingsIcon from '../../../public/Icons/configSettingsIcon.svg'


import selectedLightIcon from '../../../public/Icons/lightModeOn.svg';
import selectedDarkIcon from '../../../public/Icons/darkModeOn.svg';
import dropDownLightIcon from '../../../public/Icons/lightModeOff.svg';
import dropDownDarkIcon from '../../../public/Icons/adminmobileDark.svg';

class SideMenu extends React.Component {
  static defaultProps = {
    isSuperAdmin: false,
    privileges: []
  };
  constructor(props) {
    super(props);
    this.state = {
      isOpen: 0,
      location: '',
      homepageSettings: false
    }
    this.openMenu = this.openMenu.bind(this);
    this.openClose = this.openClose.bind(this);
  }
  componentDidMount() {
    this.setState({
      location: history.location.pathname
    })
  }
  componentDidUpdate(prevProps, prevState) {
    const { location } = this.props;
    if (prevState.location !== location) {
      this.setState({
        location
      })
    }
  }
  async openMenu() {
    this.setState({
      isOpen: 1,
    })
  }
  async openClose() {
    this.setState({
      isOpen: 0,
    })
  }
  render() {
    const { location } = this.state;
    const { adminLogout, isSuperAdmin, privileges, currentLocale, openHeaderModal, selectedTheme, choseToTheme } = this.props;
    let menuActive = selectedTheme === "light" ? s.activeMenu : s.activeMenuDark;
    return (
      <div className={cx(s.sideMenuBg, 'bgBlack')}>
        <div className={cx(s.siteAdminHeader, 'bgBlack')}>
          <img src={welcomeIcon} className={s.welcomeIconCss} />
          <span><FormattedMessage {...messages.siteAdmin} /></span>
        </div>
        <div className={cx(s.sideMenuList, 'sideMenu', 'sideMenuScroll')}>
          <div>
            <Link to={''} onClick={() => adminLogout()} className={cx(s.logoutIconMobile, 'd-block d-md-none', 'logoutIconMobileRTL')}>
              <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell)}>
                <img src={LogOutIconMobile} className={cx(s.sideMenuIcon)} />
              </span>
            </Link>
          </div>
          <Navbar expand="lg">
            <div onClick={() => this.openMenu()}>
              <div className="nav-container">
                <div className={cx("button  d-block d-md-none")} tabIndex="0">
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                </div>
              </div>
            </div>
            <div className={cx({ [s.menuOpen]: this.state.isOpen == 1 }, s.mobileMenu, 'bgBlack')}>
              <div className={cx({ [s.menuClose]: this.state.isOpen == 0 }, s.rightMenuClose, 'd-block d-md-none')}>
                <div className={cx(s.closeColor, 'closeColorRTL', 'bgBlack')} onClick={() => this.openClose()} >
                  ×
                </div>
              </div>
              <Nav className="mr-auto">
                <div onClick={() => { choseToTheme('light') }}>
                  <Link to="#" className={cx('d-block d-sm-block d-md-none d-lg-none d-xl-none', s.sideNavitem, s.responsiveDasboradMargin)} onClick={() => this.openClose()}>
                    <span className={cx(s.themeIconAlignment, { [s.selectedThemeColor]: selectedTheme === "light" })}>

                      {
                        selectedTheme && selectedTheme === "light" ?
                          <img src={selectedLightIcon} alt='' className={cx(s.themeIcon, 'themeIconRTL')} /> :
                          <img src={dropDownLightIcon} alt='' className={cx(s.themeIcon, 'themeIconRTL')} />
                      }
                      <FormattedMessage {...messages.lightMode} />
                    </span>
                  </Link>
                </div>
                <div onClick={() => { choseToTheme('dark') }}>
                  <Link to="#" className={cx('d-block d-sm-block d-md-none d-lg-none d-xl-none', s.sideNavitem)} onClick={() => this.openClose()}>
                    <span className={cx(s.themeIconAlignment, { [s.selectedThemeColorDark]: selectedTheme === "dark" })}>
                      {
                        selectedTheme && selectedTheme === "dark" ?
                          <img src={selectedDarkIcon} alt='' className={cx(s.themeIcon, 'themeIconRTL')} /> :
                          <img src={dropDownDarkIcon} alt='' className={cx(s.themeIcon, 'themeIconRTL')} />
                      }
                      <FormattedMessage {...messages.darkMode} />
                    </span>
                  </Link>
                </div>
                <Link noLink onClick={(e) => openHeaderModal('languageModal')} className={cx(s.sideNavitem, s.displayTable, 'd-block d-md-none', s.languageSelector, 'sideNavitemRTL')}>
                  <span className={cx(s.languageIcon, s.displayTableCell)}>
                    <img src={LanguageIcon} className={cx(s.sideMenuIcon, s.filterMobile)} />
                  </span>
                  <span className={cx(s.vtrMiddle, s.displayTableCell)}>
                    {formatLocale(currentLocale)}
                  </span>
                </Link>
                <Link to={'/siteadmin'} className={cx(s.sideNavitem, s.displayTable, 'sideNavitemRTL', { [menuActive]: location == '/siteadmin' }, { ['sideMenuActive']: location == '/siteadmin' })} onClick={() => this.openClose()}>
                  <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell)}>
                    <img src={DashboardIcon} className={s.sideMenuIcon} />
                  </span>
                  <span className={cx(s.vtrMiddle, s.displayTableCell, 'textAlignRightRTL')}>
                    <FormattedMessage {...messages.manageDashboard} />
                  </span>
                </Link>
                {
                  validatePrivilege(1, privileges) &&
                  <Link to={'/siteadmin/settings/site'} className={cx(s.sideNavitem, s.displayTable, 'sideNavitemRTL', { ['sideMenuActive']: location === '/siteadmin/settings/site' }, { [menuActive]: location === '/siteadmin/settings/site' })} onClick={() => this.openClose()}>
                    <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell)}>
                      <img src={SiteSettingIcon} className={cx(s.sideMenuIcon, s.noFilter)} />
                    </span>
                    <span className={cx(s.vtrMiddle, s.displayTableCell, 'textAlignRightRTL')}>
                      <FormattedMessage {...messages.siteSettings} />
                    </span>
                  </Link>
                }
                {
                  validatePrivilege(1, privileges) &&
                  <Link to={'/siteadmin/settings/mobile-app'} className={cx(s.sideNavitem, s.displayTable, 'sideNavitemRTL', { [menuActive]: location === '/siteadmin/settings/mobile-app' }, { ['sideMenuActive']: location === '/siteadmin/settings/mobile-app' })} onClick={() => this.openClose()}>
                    <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell)}>
                      <img src={mobileAppIcon} className={cx(s.sideMenuIcon, s.noFilter)} />
                    </span>
                    <span className={cx(s.vtrMiddle, s.displayTableCell, 'textAlignRightRTL')}>
                      <FormattedMessage {...messages.mobileSettings} />
                    </span>
                  </Link>
                }
                {
                  validatePrivilege(1, privileges) &&
                  <Link to={'/siteadmin/settings/configuration'} className={cx(s.sideNavitem, s.displayTable, 'sideNavitemRTL', { [menuActive]: location === '/siteadmin/settings/configuration' })} onClick={() => this.openClose()}>
                    <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell)}>
                      <img src={configSettingsIcon} className={cx(s.sideMenuIcon, s.noFilter)} />
                    </span>
                    <span className={cx(s.vtrMiddle, s.displayTableCell, 'textAlignRightRTL')}>
                      <FormattedMessage {...messages.configSettings} />
                    </span>
                  </Link>
                }
                {
                  validatePrivilege(2, privileges) &&
                  <div>
                    <div id='Homepage' className={cx(s.sideNavitem, 'sideNavitemRTL', 'darkBtnHover')}>
                      <Button
                        bsStyle="link"
                        className={cx(s.button, bt.noPadding, s.noBorderBtn, menuActive, s.btnWidth, 'textWhite', 'btnPrimaryDarkMenu')}
                        onClick={() => {
                          this.setState({
                            homepageSettings: !this.state.homepageSettings
                          })
                          // let element = document.getElementById("Homepage")
                          // element.classList.add(menuActive);
                        }
                        }>
                        <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell)}>
                          <img src={HomeSettingIcon} className={cx(s.sideMenuIcon, s.noFilter)} />
                        </span>
                        <span className={cx(s.vtrMiddle, s.displayTableCell, 'textAlignRightRTL')}>
                          <FormattedMessage {...messages.HomeSiteSettings} />
                        </span>
                        {
                          this.state.homepageSettings &&
                          <span className={s.displayTableCell}>
                            <FontAwesome.FaAngleUp className={s.navigationIcon} />
                          </span>
                        }

                        {
                          !this.state.homepageSettings && <span className={s.displayTableCell}><FontAwesome.FaAngleDown className={s.navigationIcon} /></span>
                        }
                      </Button>
                    </div>
                    <Collapse in={this.state.homepageSettings}>
                      <div>
                        <Link to={'/siteadmin/homepage/banner'} className={cx(s.sideNavitem, s.displayTable, s.sideNavitemCollapsePadding, 'sideNavitemRTL', { [menuActive]: location.startsWith('/siteadmin/homepage-homesettings') })} onClick={() => this.openClose()}>
                          <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell, s.sideNavitemCollapse, 'rightArrowRTL')}>
                            <img src={RightArrowIcon} className={cx(s.sideMenuIcon, s.sideNavitemCollapseIcon)} />
                          </span>
                          <span className={cx(s.vtrMiddle, s.sideNavitemCollapseText, s.displayTableCell, 'textAlignRightRTL')}>
                            <FormattedMessage {...messages.homeSectionSettings} />
                          </span>
                        </Link>
                        <Link to={'/siteadmin/homepage/category'} className={cx(s.sideNavitem, s.displayTable, s.sideNavitemCollapsePadding, 'sideNavitemRTL', { [menuActive]: location.startsWith('/siteadmin/homepage-citysettings') })} onClick={() => this.openClose()}>
                          <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell, s.sideNavitemCollapse, 'rightArrowRTL')}>
                            <img src={RightArrowIcon} className={cx(s.sideMenuIcon, s.sideNavitemCollapseIcon)} />
                          </span>
                          <span className={cx(s.vtrMiddle, s.sideNavitemCollapseText, s.displayTableCell, 'textAlignRightRTL')}>
                            <FormattedMessage {...messages.citySectionSettings} />
                          </span>
                        </Link>
                        <Link to={'/siteadmin/homepage/topfeature'} className={cx(s.sideNavitem, s.displayTable, s.sideNavitemCollapsePadding, 'sideNavitemRTL', { [menuActive]: location.startsWith('/siteadmin/homepage-aboutsettings') })} onClick={() => this.openClose()}>
                          <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell, s.sideNavitemCollapse, 'rightArrowRTL')}>
                            <img src={RightArrowIcon} className={cx(s.sideMenuIcon, s.sideNavitemCollapseIcon)} />
                          </span>
                          <span className={cx(s.vtrMiddle, s.sideNavitemCollapseText, s.displayTableCell, 'textAlignRightRTL')}>
                            <FormattedMessage {...messages.aboutSectionSettings} />
                          </span>
                        </Link>
                        <Link to={'/siteadmin/homepage/rider'} className={cx(s.sideNavitem, s.displayTable, s.sideNavitemCollapsePadding, 'sideNavitemRTL', { [menuActive]: location.startsWith('/siteadmin/homepage-safetysettings') })} onClick={() => this.openClose()}>
                          <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell, s.sideNavitemCollapse, 'rightArrowRTL')}>
                            <img src={RightArrowIcon} className={cx(s.sideMenuIcon, s.sideNavitemCollapseIcon)} />
                          </span>
                          <span className={cx(s.vtrMiddle, s.sideNavitemCollapseText, s.displayTableCell, 'textAlignRightRTL')}>
                            <FormattedMessage {...messages.safetySectionSettings} />
                          </span>
                        </Link>
                        <Link to={'/siteadmin/homepage/driver'} className={cx(s.sideNavitem, s.displayTable, s.sideNavitemCollapsePadding, 'sideNavitemRTL', { [menuActive]: location.startsWith('/siteadmin/homepage-signupsettings') })} onClick={() => this.openClose()}>
                          <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell, s.sideNavitemCollapse, 'rightArrowRTL')}>
                            <img src={RightArrowIcon} className={cx(s.sideMenuIcon, s.sideNavitemCollapseIcon)} />
                          </span>
                          <span className={cx(s.vtrMiddle, s.sideNavitemCollapseText, s.displayTableCell, 'textAlignRightRTL')}>
                            <FormattedMessage {...messages.signupSectionSettings} />
                          </span>
                        </Link>
                        <Link to={'/siteadmin/homepage/footer'} className={cx(s.sideNavitem, s.displayTable, s.sideNavitemCollapsePadding, 'sideNavitemRTL', { [menuActive]: location.startsWith('/siteadmin/homepage-footersettings') })} onClick={() => this.openClose()}>
                          <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell, s.sideNavitemCollapse, 'rightArrowRTL')}>
                            <img src={RightArrowIcon} className={cx(s.sideMenuIcon, s.sideNavitemCollapseIcon)} />
                          </span>
                          <span className={cx(s.vtrMiddle, s.sideNavitemCollapseText, s.displayTableCell, 'textAlignRightRTL')}>
                            <FormattedMessage {...messages.footerSectionSettings} />
                          </span>
                        </Link>
                      </div>
                    </Collapse>
                  </div>
                }
                {isSuperAdmin && <div>
                  <div id='Homepage' className={cx(s.sideNavitem, 'sideNavitemRTL', 'darkBtnHover')}>
                    <Button
                      bsStyle="link"
                      className={cx(s.button, bt.noPadding, s.noBorderBtn, menuActive, s.btnWidth, 'textWhite', 'btnPrimaryDarkMenu')}
                      onClick={() => {
                        this.setState({
                          subAdmin: !this.state.subAdmin
                        })
                        // let element = document.getElementById("Homepage")
                        // element.classList.add(menuActive);
                      }
                      }>
                      <span className={cx(s.vtrTop, s.iconWidth, s.iconWidth, s.displayTableCell)}>
                        <img src={manageAdminIcon} className={cx(s.sideMenuIcon, s.noFilter)} />
                      </span>
                      <span className={cx(s.vtrMiddle, s.displayTableCell, 'textAlignRightRTL')}>
                        <FormattedMessage {...messages.manageAdmin} />
                      </span>
                      {
                        this.state.subAdmin &&
                        <span className={s.displayTableCell}>
                          <FontAwesome.FaAngleUp className={s.navigationIcon} />
                        </span>
                      }

                      {
                        !this.state.subAdmin && <span className={s.displayTableCell}><FontAwesome.FaAngleDown className={s.navigationIcon} /></span>
                      }
                    </Button>
                  </div>
                  <Collapse in={this.state.subAdmin} className={s.subMenu}>
                    <div>
                      <Link to={'/siteadmin/admin-users'} className={cx(s.sideNavitem, s.displayTable, s.sideNavitemCollapsePadding, 'sideNavitemRTL', { [menuActive]: location.startsWith('/siteadmin/admin-users') })} onClick={() => this.openClose()}>
                        <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell, s.sideNavitemCollapse, 'rightArrowRTL')}>
                          <img src={RightArrowIcon} className={cx(s.sideMenuIcon, s.sideNavitemCollapseIcon)} />
                        </span>
                        <span className={cx(s.vtrMiddle, s.sideNavitemCollapseText, s.displayTableCell, 'textAlignRightRTL')}>
                          <FormattedMessage {...messages.manageAdminUsers} />
                        </span>
                      </Link>

                      <Link to={'/siteadmin/admin-roles'} className={cx(s.sideNavitem, s.displayTable, s.sideNavitemCollapsePadding, 'sideNavitemRTL', { [menuActive]: location.startsWith('/siteadmin/admin-roles') })} onClick={() => this.openClose()}>
                        <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell, s.sideNavitemCollapse, 'rightArrowRTL')}>
                          <img src={RightArrowIcon} className={cx(s.sideMenuIcon, s.sideNavitemCollapseIcon)} />
                        </span>
                        <span className={cx(s.vtrMiddle, s.sideNavitemCollapseText, s.displayTableCell, 'textAlignRightRTL')}>
                          <FormattedMessage {...messages.manageAdminRoles} />
                        </span>
                      </Link>
                    </div>
                  </Collapse>
                </div>
                }
                {
                  validatePrivilege(3, privileges) &&
                  <Link to={'/siteadmin/riders'} className={cx(s.sideNavitem, s.displayTable, 'sideNavitemRTL', 'menuIconRTL', { [menuActive]: location.startsWith('/siteadmin/riders') })} onClick={() => this.openClose()}>
                    <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell)}>
                      <img src={RidersIcon} className={s.sideMenuIcon} />
                    </span>
                    <span className={cx(s.vtrMiddle, s.displayTableCell, 'textAlignRightRTL')}>
                      <FormattedMessage {...messages.ridersMenu} />
                    </span>
                  </Link>}
                {
                  validatePrivilege(4, privileges) &&
                  <Link to={'/siteadmin/drivers'} className={cx(s.sideNavitem, s.displayTable, 'sideNavitemRTL', 'menuIconRTL', { [menuActive]: location.startsWith('/siteadmin/drivers') })} onClick={() => this.openClose()}>
                    <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell)}>
                      <img src={DriversIcon} className={s.sideMenuIcon} />
                    </span>
                    <span className={cx(s.vtrMiddle, s.displayTableCell, 'textAlignRightRTL')}>
                      <FormattedMessage {...messages.driverMenu} />
                    </span>
                  </Link>}
                {validatePrivilege(5, privileges) && <Link to={'/siteadmin/vehicles'} className={cx(s.sideNavitem, s.displayTable, 'sideNavitemRTL', { [menuActive]: location.startsWith('/siteadmin/vehicles') })} onClick={() => this.openClose()}>
                  <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell)}>
                    <img src={VehiclesIcon} className={s.sideMenuIcon} />
                  </span>
                  <span className={cx(s.vtrMiddle, s.displayTableCell, 'textAlignRightRTL')}>
                    <FormattedMessage {...messages.vehiclesMenu} />
                  </span>
                </Link>}
                {
                  validatePrivilege(6, privileges) &&
                  <Link to={'/siteadmin/category'} className={cx(s.sideNavitem, s.displayTable, 'sideNavitemRTL', { [menuActive]: location.startsWith('/siteadmin/category') })} onClick={() => this.openClose()}>
                    <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell)}>
                      <img src={CategoriesIcon} className={s.sideMenuIcon} />
                    </span>
                    <span className={cx(s.vtrMiddle, s.displayTableCell, 'textAlignRightRTL')}>
                      <FormattedMessage {...messages.categoryMenu} />
                    </span>
                  </Link>}
                {
                  validatePrivilege(18, privileges) &&
                  <Link to={'/siteadmin/payment-gateway'} className={cx(s.sideNavitem, s.displayTable, 'sideNavitemRTL', { [menuActive]: location.startsWith('/siteadmin/payment-gateway') })} onClick={() => this.openClose()}>
                    <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell)}>
                      <img src={CategoriesIcon} className={s.sideMenuIcon} />
                    </span>
                    <span className={cx(s.vtrMiddle, s.displayTableCell, 'textAlignRightRTL')}>
                      <FormattedMessage {...messages.managePaymentGateWay} />
                    </span>
                  </Link>}
                {
                  validatePrivilege(7, privileges) &&
                  <Link to={'/siteadmin/location'} className={cx(s.sideNavitem, s.displayTable, 'sideNavitemRTL', { [menuActive]: (location.startsWith('/siteadmin/location') || location.startsWith('/siteadmin/edit-location')) })} onClick={() => this.openClose()}>
                    <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell)}>
                      <img src={ManageLocationIcon} className={cx(s.sideMenuIcon, s.noFilter)} />
                    </span>
                    <span className={cx(s.vtrMiddle, s.displayTableCell, 'textAlignRightRTL')}>
                      <FormattedMessage {...messages.location} />
                    </span>
                  </Link>}
                {
                  validatePrivilege(8, privileges) &&
                  <Link to={'/siteadmin/pricing/list'} className={cx(s.sideNavitem, s.displayTable, 'sideNavitemRTL', { [menuActive]: location.startsWith('/siteadmin/pricing') })} onClick={() => this.openClose()}>
                    <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell)}>
                      <img src={manageFareIcon} className={cx(s.sideMenuIcon, s.noFilter)} />
                    </span>
                    <span className={cx(s.vtrMiddle, s.displayTableCell, 'textAlignRightRTL')}>
                      <FormattedMessage {...messages.manageFare} />
                    </span>
                  </Link>}
                {
                  validatePrivilege(17, privileges) &&
                  <Link to={'/siteadmin/manual-bookings'} className={cx(s.sideNavitem, s.displayTable, 'sideNavitemRTL', { [menuActive]: location.startsWith('/siteadmin/manual-bookings') })} onClick={() => this.openClose()}>
                    <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell)}>
                      <img src={manualBookingsIcon} className={cx(s.sideMenuIcon, s.noFilter)} />
                    </span>
                    <span className={cx(s.vtrMiddle, s.displayTableCell, 'textAlignRightRTL')}>
                      <FormattedMessage {...messages.manualBookings} />
                    </span>
                  </Link>
                }
                {
                  validatePrivilege(9, privileges) &&
                  <Link to={'/siteadmin/schedule-bookings'} className={cx(s.sideNavitem, s.displayTable, 'sideNavitemRTL', { [menuActive]: location.startsWith('/siteadmin/schedule-bookings') })} onClick={() => this.openClose()}>
                    <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell)}>
                      <img src={scheduleIcon} />
                    </span>
                    <span className={cx(s.vtrMiddle, s.displayTableCell, 'textAlignRightRTL')}>
                      <FormattedMessage {...messages.scheduleBooking} />
                    </span>
                  </Link>
                }
                {
                  validatePrivilege(9, privileges) &&
                  <Link to={'/siteadmin/bookings'} className={cx(s.sideNavitem, s.displayTable, 'sideNavitemRTL', { [menuActive]: location.startsWith('/siteadmin/bookings') })} onClick={() => this.openClose()}>
                    <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell)}>
                      <img src={BookingsIcon} className={s.sideMenuIcon} />
                    </span>
                    <span className={cx(s.vtrMiddle, s.displayTableCell, 'textAlignRightRTL')}>
                      <FormattedMessage {...messages.bookingsMenu} />
                    </span>
                  </Link>
                }
                {
                  validatePrivilege(9, privileges) &&
                  <Link to={'/siteadmin/completed-bookings'} className={cx(s.sideNavitem, s.displayTable, 'sideNavitemRTL', { [menuActive]: location.startsWith('/siteadmin/completed-bookings') })} onClick={() => this.openClose()}>
                    <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell)}>
                      <img src={CompletedBooking} className={cx(s.sideMenuIcon, s.noFilter)} />
                    </span>
                    <span className={cx(s.vtrMiddle, s.displayTableCell, 'textAlignRightRTL')}>
                      <FormattedMessage {...messages.manageCompletedTrips} />
                    </span>
                  </Link>}
                {
                  validatePrivilege(9, privileges) &&
                  <Link to={'/siteadmin/cancelled-bookings'} className={cx(s.sideNavitem, s.displayTable, 'sideNavitemRTL', { [menuActive]: location.startsWith('/siteadmin/cancelled-bookings') })} onClick={() => this.openClose()}>
                    <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell)}>
                      <img src={CancalBookingIcon} className={cx(s.sideMenuIcon, s.noFilter)} />
                    </span>
                    <span className={cx(s.vtrMiddle, s.displayTableCell, 'textAlignRightRTL')}>
                      <FormattedMessage {...messages.manageCancelledTrips} />
                    </span>
                  </Link>}

                <Link to={'/siteadmin/tracking'} className={cx(s.sideNavitem, s.displayTable, 'sideNavitemRTL', { [menuActive]: location == '/siteadmin/tracking' })} onClick={() => this.openClose()}>
                  <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell)}>
                    <img src={trackingIcon} className={cx(s.sideMenuIcon, s.noFilter)} />
                  </span>
                  <span className={cx(s.vtrMiddle, s.displayTableCell, 'textAlignRightRTL')}>
                    <FormattedMessage {...messages.tracking} />
                  </span>
                </Link>

                {
                  validatePrivilege(10, privileges) &&
                  <Link to={'/siteadmin/ratings'} className={cx(s.sideNavitem, s.displayTable, 'sideNavitemRTL', { [menuActive]: location == '/siteadmin/ratings' })} onClick={() => this.openClose()}>
                    <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell)}>
                      <img src={RatingIcon} className={cx(s.sideMenuIcon, s.noFilter)} />
                    </span>
                    <span className={cx(s.vtrMiddle, s.displayTableCell, 'textAlignRightRTL')}>
                      <FormattedMessage {...messages.ratings} />
                    </span>
                  </Link>}
                {
                  validatePrivilege(11, privileges) &&
                  <Link to={'/siteadmin/promo-code/list'} className={cx(s.sideNavitem, s.displayTable, 'sideNavitemRTL', { [menuActive]: location.startsWith('/siteadmin/promo-code') })} onClick={() => this.openClose()}>
                    <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell)}>
                      <img src={PromoCodeIcon} className={s.sideMenuIcon} />
                    </span>
                    <span className={cx(s.vtrMiddle, s.displayTableCell, 'textAlignRightRTL')}>
                      <FormattedMessage {...messages.managePromoCode} />
                    </span>
                  </Link>}
                <Link to={'/siteadmin/change/admin'} className={cx(s.sideNavitem, s.displayTable, 'sideNavitemRTL', { [menuActive]: location == '/siteadmin/change/admin' })} onClick={() => this.openClose()}>
                  <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell)}>
                    <img src={ChangePasswordIcon} className={cx(s.sideMenuIcon, s.noFilter)} />
                  </span>
                  <span className={cx(s.vtrMiddle, s.displayTableCell, 'textAlignRightRTL')}>
                    <FormattedMessage {...messages.changePassword} />
                  </span>
                </Link>
                {
                  validatePrivilege(12, privileges) &&
                  <Link to={'/siteadmin/notifications'} className={cx(s.sideNavitem, s.displayTable, 'sideNavitemRTL', { [menuActive]: location == '/siteadmin/notifications' })} onClick={() => this.openClose()}>
                    <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell)}>
                      <img src={NotificationIcon} className={cx(s.sideMenuIcon, s.noFilter)} />
                    </span>
                    <span className={cx(s.vtrMiddle, s.displayTableCell, 'textAlignRightRTL')}>
                      <FormattedMessage {...messages.manageNotifications} />
                    </span>
                  </Link>}
                {isSuperAdmin && <Link to={'/siteadmin/currency'} className={cx(s.sideNavitem, s.displayTable, 'sideNavitemRTL', { [menuActive]: location == '/siteadmin/currency' })} onClick={() => this.openClose()}>
                  <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell)}>
                    <img src={ManageCurrencyIcon} className={cx(s.sideMenuIcon, s.noFilter)} />
                  </span>
                  <span className={cx(s.vtrMiddle, s.displayTableCell, 'textAlignRightRTL')}>
                    <FormattedMessage {...messages.manageCurrency} />
                  </span>
                </Link>}
                {
                  validatePrivilege(13, privileges) &&
                  <Link to={'/siteadmin/cancel-reasons'} className={cx(s.sideNavitem, s.displayTable, 'sideNavitemRTL', { [menuActive]: location.startsWith('/siteadmin/cancel-reasons') })} onClick={() => this.openClose()}>
                    <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell)}>
                      <img src={CancelFaildIcon} className={cx(s.sideMenuIcon, s.noFilter)} />
                    </span>
                    <span className={cx(s.vtrMiddle, s.displayTableCell, 'textAlignRightRTL')}>
                      <FormattedMessage {...messages.manageCancelReason} />
                    </span>
                  </Link>}
                {
                  validatePrivilege(14, privileges) &&
                  <Link to={'/siteadmin/payout'} className={cx(s.sideNavitem, s.displayTable, 'sideNavitemRTL', { [menuActive]: location.startsWith('/siteadmin/payout') })} onClick={() => this.openClose()}>
                    <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell)}>
                      <img src={PayOutManageIcon} className={cx(s.sideMenuIcon, s.noFilter)} />
                    </span>
                    <span className={cx(s.vtrMiddle, s.displayTableCell, 'textAlignRightRTL')}>
                      <FormattedMessage {...messages.managePayout} />
                    </span>
                  </Link>}
                {
                  validatePrivilege(14, privileges) &&
                  <Link to={'/siteadmin/failed-payout'} className={cx(s.sideNavitem, s.displayTable, 'sideNavitemRTL', { [menuActive]: location.startsWith('/siteadmin/failed-payout') })} onClick={() => this.openClose()}>
                    <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell)}>
                      <img src={PayOutManageFaildIcon} className={cx(s.sideMenuIcon, s.noFilter)} />
                    </span>
                    <span className={cx(s.vtrMiddle, s.displayTableCell, 'textAlignRightRTL')}>
                      <FormattedMessage {...messages.manageFailedPayout} />
                    </span>
                  </Link>}
                {
                  validatePrivilege(15, privileges) &&
                  <Link to={'/siteadmin/staticpage/manage'} className={cx(s.sideNavitem, s.displayTable, 'sideNavitemRTL', { [menuActive]: location.startsWith('/siteadmin/staticpage/') })} onClick={() => this.openClose()}>
                    <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell)}>
                      <img src={staticPageIcon} className={cx(s.sideMenuIcon, s.noFilter)} />
                    </span>
                    <span className={cx(s.vtrMiddle, s.displayTableCell, 'textAlignRightRTL')}>
                      <FormattedMessage {...messages.staticpageManagement} />
                    </span>
                  </Link>}
                {
                  validatePrivilege(15, privileges) &&
                  <Link to={'/siteadmin/contentpage/manage'} className={cx(s.sideNavitem, s.displayTable, 'sideNavitemRTL', { [menuActive]: location.startsWith('/siteadmin/contentpage/') })} onClick={() => this.openClose()}>
                    <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell)}>
                      <img src={contentPageIcon} className={cx(s.sideMenuIcon, s.noFilter)} />
                    </span>
                    <span className={cx(s.vtrMiddle, s.displayTableCell, 'textAlignRightRTL')}>
                      <FormattedMessage {...messages.contentpageManagement} />
                    </span>
                  </Link>}
                {
                  validatePrivilege(16, privileges) &&
                  <Link to={'/siteadmin/precaution-notification'} className={cx(s.sideNavitem, s.displayTable, 'sideNavitemRTL', { [menuActive]: location == '/siteadmin/precaution-notification' })} onClick={() => this.openClose()}>
                    <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell)}>
                      <img src={precautionIcon} className={cx(s.sideMenuIcon, s.noFilter)} />
                    </span>
                    <span className={cx(s.vtrMiddle, s.displayTableCell, 'textAlignRightRTL')}>
                      <FormattedMessage {...messages.precautionNotification} />
                    </span>
                  </Link>}
                <Link to={'/'} className={cx(s.sideNavitem, 'sideNavitemRTL', s.displayTable, s.hiddenMd)} onClick={() => this.openClose()}>
                  <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell)}>
                    <img src={MainSiteIcon} className={cx(s.sideMenuIcon, s.filterMobile)} />
                  </span>
                  <span className={cx(s.vtrMiddle, s.displayTableCell, 'textAlignRightRTL')}>
                    <FormattedMessage {...messages.goToMainSite} />
                  </span>
                </Link>
                <Link to={''} onClick={() => adminLogout()} className={cx(s.sideNavitem, s.displayTable, 'sideNavitemRTL', s.paddingBottomextra, s.hiddenMd)}>
                  <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell)}>
                    <img src={LogOutIcon} className={cx(s.sideMenuIcon, s.filterMobile)} />
                  </span>
                  <span className={cx(s.vtrMiddle, s.displayTableCell, 'textAlignRightRTL')}>
                    <FormattedMessage {...messages.logout} />
                  </span>
                </Link>
              </Nav>
            </div>
          </Navbar>
        </div>
      </div>
    );
  }
}
const mapState = state => ({
  isSuperAdmin: state.runtime.isSuperAdmin,
  privileges: state.adminPrevileges.privileges && state.adminPrevileges.privileges.privileges,
  currentLocale: state.intl.locale,
  selectedTheme: state.currency.theme
});
const mapDispatch = {
  adminLogout,
  openHeaderModal,
  choseToTheme
};
export default compose(injectIntl,
  withStyles(s, bt),
  (connect(mapState, mapDispatch))
)(SideMenu);